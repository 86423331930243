import React, { Suspense } from 'react';
import ReactGA from 'react-ga';
import { Toaster } from 'react-hot-toast';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import handleRequestError from './helpers/error';
import {
  AuthProvider,
  JurorProvider,
  RequireAuth,
  RequireJuror,
} from './hooks/useAuth';
import { ConfigProvider } from './hooks/useConfig';
import Layout from './modules/layout';
import Loader from './modules/loader';
import Admin from './routes/admin';
import StaffChat from './routes/admin/chat';
import Dashboard from './routes/admin/dashboard';
import CreateGroup from './routes/admin/groups/create';
import JurorGroups from './routes/admin/groups/overview';
import JurorsOverview from './routes/admin/jurors/overview';
import Login from './routes/admin/login';
import SessionDetail from './routes/admin/session/detail';
import ArchivedSessions from './routes/admin/sessions/archived';
import CreateSession from './routes/admin/sessions/create';
import EvaluationStatus from './routes/admin/sessions/evaluationStatus';
import InformationMaterial from './routes/admin/sessions/informationMaterial';
import Meetings from './routes/admin/sessions/meetings';
import CreateMeeting from './routes/admin/sessions/meetings/create';
import SessionOverview from './routes/admin/sessions/overview';
import SubmissionOverview from './routes/admin/sessions/submissionOverview';
import ThresholdCalculator from './routes/admin/sessions/thresholdCalculator';
import DashboardJuror from './routes/juror/dashboard';
import Entries from './routes/juror/entries';
import EntryDetail from './routes/juror/entry/detail';
import QRScanner from './routes/juror/entry/scan';
import Help from './routes/juror/help';
import SessionEnded from './routes/juror/sessionEnded';
import JurorLogin from './routes/juror/login';
import JurorLogout from './routes/juror/logout';
import './styles/__style.scss';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const oldFetch = global.fetch;
global.fetch = (...args) => {
  const url = args[0];

  if (url.startsWith('/if-api')) {
    args[0] = BACKEND_URL + url;

    if (args[1]) {
      args[1].credentials = 'include';
    } else {
      args[1] = { credentials: 'include' };
    }
  }
  return oldFetch(...args).then(handleRequestError);
};

export default function App() {
  const location = useLocation();
  if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }

  React.useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.pageview(window?.location?.pathname + window?.location?.search);
    }
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        containerStyle={{ zIndex: 100000 }}
      />
      <AuthProvider>
        <JurorProvider>
          <ConfigProvider>
            <Routes>
              <Route path="admin" element={<Admin />}>
                <Route index element={<Login />} />
                <Route path="session">
                  <Route
                    path=":id"
                    element={
                      <RequireAuth>
                        <SessionDetail />
                      </RequireAuth>
                    }
                  />
                  <Route
                    index
                    element={<Navigate to="/admin/sessions/overview" />}
                  />
                </Route>
                <Route path="sessions">
                  <Route
                    path="overview"
                    element={
                      <RequireAuth>
                        <SessionOverview />
                        <Outlet />
                      </RequireAuth>
                    }
                  >
                    <Route path="create" element={<CreateSession />} />
                  </Route>
                  <Route
                    path="archived"
                    element={
                      <RequireAuth>
                        <ArchivedSessions />
                      </RequireAuth>
                    }
                  />

                  <Route
                    path="evaluation-status"
                    element={
                      <RequireAuth>
                        <EvaluationStatus />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="threshold-calculator"
                    element={
                      <RequireAuth>
                        <ThresholdCalculator />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="meetings"
                    element={
                      <RequireAuth>
                        <Meetings />
                        <Outlet />
                      </RequireAuth>
                    }
                  >
                    <Route path="create" element={<CreateMeeting />} />
                  </Route>
                  <Route
                    path="information-material"
                    element={
                      <RequireAuth>
                        <InformationMaterial />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="submission-overview"
                    element={
                      <RequireAuth>
                        <SubmissionOverview />
                      </RequireAuth>
                    }
                  />
                  <Route index element={<Navigate to="overview" />} />
                </Route>
                <Route path="groups">
                  <Route
                    path="overview"
                    element={
                      <RequireAuth>
                        <JurorGroups />
                        <Outlet />
                      </RequireAuth>
                    }
                  >
                    <Route path="create" element={<CreateGroup />} />
                  </Route>
                </Route>
                <Route path="jurors">
                  <Route
                    path="overview"
                    element={
                      <RequireAuth>
                        <JurorsOverview />
                      </RequireAuth>
                    }
                  />
                  <Route index element={<Navigate to="overview" />} />
                </Route>
                <Route
                  path="chat"
                  element={
                    <RequireAuth>
                      <StaffChat />
                    </RequireAuth>
                  }
                />

                <Route
                  path="dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="closed" element={<SessionEnded />} />
              <Route path="juror" element={<Layout type={'juror'} />}>
                <Route
                  index
                  element={
                    <RequireJuror>
                      <JurorLogin />
                    </RequireJuror>
                  }
                />
                <Route path="logout" element={<JurorLogout />} />
                <Route
                  path=":id"
                  element={
                    <RequireJuror>
                      <JurorLogin />
                    </RequireJuror>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <RequireJuror>
                      <DashboardJuror />
                    </RequireJuror>
                  }
                />
                <Route
                  path="entries"
                  element={
                    <RequireJuror>
                      <Entries />
                    </RequireJuror>
                  }
                />
                <Route path="entry">
                  <Route path=":entryId">
                    <Route
                      index
                      element={
                        <RequireJuror>
                          <EntryDetail />
                        </RequireJuror>
                      }
                    />
                    <Route
                      path=":jurorId"
                      element={
                        <RequireJuror>
                          <EntryDetail />
                        </RequireJuror>
                      }
                    />
                  </Route>
                  <Route
                    path="scan"
                    element={
                      <RequireJuror>
                        <QRScanner />
                      </RequireJuror>
                    }
                  >
                    <Route
                      path=":entryId"
                      element={
                        <RequireJuror>
                          <QRScanner />
                        </RequireJuror>
                      }
                    />
                  </Route>
                </Route>
                <Route
                  path="help"
                  element={
                    <RequireJuror>
                      <Help />
                    </RequireJuror>
                  }
                />
              </Route>
              <Route path="/" element={<Navigate to="/admin/login" />} />
            </Routes>
          </ConfigProvider>
        </JurorProvider>
      </AuthProvider>
    </Suspense>
  );
}
