import { faEmptySet, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { combine } from '../../helpers/styles';
import { useJuror } from '../../hooks/useAuth';
import { useConfig } from '../../hooks/useConfig';
import Submission from '../../models/submission';
import Avatar from '../avatar';
import Loading from '../loading';
import { matchRoles } from '../../helpers/roles';
import { ROLES } from '../../config/permissions/roles';
import styles from './styles.module.scss';

const ScoreTable = ({ submission: initialSubmissionData }) => {
  const { t } = useTranslation();
  const [submission, setSubmission] = useState([]);
  const [loading, setLoading] = useState(true);
  const { juror, jurorGroup, role } = useJuror();
  const { EVALUATION_CRITERIA_ORDER } = useConfig();

  useEffect(() => {
    if (initialSubmissionData && jurorGroup) {
      const jurorGroupId = matchRoles(role, [ROLES.chairPerson, ROLES.staff])
        ? juror.jurorGroups.find(
            (jg) =>
              jg.jurorGroupId ===
              initialSubmissionData?.evaluations[0].jurorGroupId
          )?.jurorGroupId
        : jurorGroup.jurorGroupId;

      const fetchPoints = async () => {
        const data = await Submission.getPoints(
          jurorGroupId,
          initialSubmissionData.submissionId
        );

        // map the criterion names/ids to the points array of each juror.
        // sort the criteria/points to match the config order of the criteria
        data.jurors = data.jurors.map((juror) => ({
          ...juror,
          points: juror.points
            .map((points, idx) => ({
              ...points,
              criterionId: data.criteria?.[idx]?.id,
              criterionName: data.criteria?.[idx]?.name,
            }))
            .sort(
              (a, b) =>
                EVALUATION_CRITERIA_ORDER.indexOf(
                  a.criterionName.toLowerCase()
                ) -
                EVALUATION_CRITERIA_ORDER.indexOf(b.criterionName.toLowerCase())
            ),
        }));

        data.criteria.sort(
          (a, b) =>
            EVALUATION_CRITERIA_ORDER.indexOf(a.name.toLowerCase()) -
            EVALUATION_CRITERIA_ORDER.indexOf(b.name.toLowerCase())
        );

        setSubmission(data);
        setLoading(false);
      };

      fetchPoints();
    }
  }, [initialSubmissionData]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th></th>
          <th>
            <FontAwesomeIcon icon={faEmptySet} className={styles.averageIcon} />{' '}
            {t('evaluation.criteria.SCORE')}
          </th>
          {submission?.criteria?.map((criterion) => (
            <th key={criterion.id}>{criterion.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr className={combine(styles.tr, styles.loading)}>
            <td colSpan={7}>
              <Loading />
            </td>
          </tr>
        )}
        {submission?.jurors
          ?.filter((jurorEvaluation) =>
            [ROLES.juror, ROLES.groupLeader].includes(jurorEvaluation.role)
          )
          ?.map((jurorEvaluation) => {
            const chairPersonMustDecide =
              jurorEvaluation?.chairPersonMustDecide;
            const isPersonalEvaluation =
              juror.jurorId === jurorEvaluation.jurorId;
            const hidePoints = isPersonalEvaluation && chairPersonMustDecide;
            const totalScore =
              jurorEvaluation?.points
                .reduce((a, b) => a + b.points, 0)
                .toFixed() || 0;

            return (
              <tr key={jurorEvaluation.jurorId} className={styles.tr}>
                <td>
                  <Avatar
                    name={`${jurorEvaluation.firstname} ${jurorEvaluation.lastname}`}
                    img={jurorEvaluation.profilePictureUrl}
                    role={jurorEvaluation.role}
                  />
                </td>
                <td>
                  {hidePoints ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    totalScore
                  )}
                </td>
                {jurorEvaluation?.points?.map((criterion) => (
                  <td key={criterion.criterionId}>
                    {hidePoints ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    ) : (
                      <>{criterion.points || 0}</>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

ScoreTable.propTypes = {
  submission: PropTypes.object,
};

ScoreTable.defaultProps = {
  submission: null,
};

export default ScoreTable;
