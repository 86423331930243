import { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/pro-light-svg-icons';
import { useJuror } from '../../../../hooks/useAuth';
import { useConfig } from '../../../../hooks/useConfig';
import { ROLES } from '../../../../config/permissions/roles';
import { AWARD_TYPES } from '../../../../models/award';
import { SESSION_TYPES } from '../../../../models/session';
import Submission from '../../../../models/submission';
import { isAwardType } from '../../../../helpers/awardTypes';
import { matchRoles } from '../../../../helpers/roles';
import { combine } from '../../../../helpers/styles';
import EcoCommentModal from './ecoCommentModal';
import styles from './styles.module.scss';

const EcoExpert = ({ submission, updateSubmission, isDetailPage }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { jurorId: impersonatingJurorId } = useParams();
  const { state } = useLocation();
  const { juror, jurorGroup, role, session } = useJuror();
  const { ENTRIES_TABLE } = useConfig();

  const hasEcoComment = !!submission.ecoComment;
  const isCommentPublished = submission.ecoCommentPublished;
  const hasVeto = submission.nominatedVeto;

  const isIconVisible =
    ENTRIES_TABLE.ACTIONS.includes('ecoComment') &&
    isAwardType(AWARD_TYPES.IF_DA, session) &&
    session.sessionType === SESSION_TYPES.FINAL_JURY &&
    (matchRoles(role, [ROLES.staff]) || isCommentPublished || hasVeto);

  const isDisabled =
    matchRoles(role, [ROLES.staff]) &&
    impersonatingJurorId &&
    impersonatingJurorId !== juror.jurorId;

  function showModal() {
    setIsModalVisible(true);
  }

  function hideModal() {
    setIsModalVisible(false);
  }

  async function handleSave({ comment, isPublished, hasVeto }) {
    // TODO: Refactor this logic to be reusable. As this is used by several components
    const jurorGroupId =
      !isDetailPage && matchRoles(role, [ROLES.chairPerson, ROLES.staff])
        ? juror.jurorGroups.find(
            (jg) => jg.jurorGroupId === submission?.evaluations[0].jurorGroupId
          )?.jurorGroupId
        : jurorGroup.jurorGroupId;

    const ecoCommentSuccess = await Submission.setEcoComment({
      jurorGroupId: state?.jurorGroupId || jurorGroupId,
      submissionId: submission.submissionId,
      value: { ecoComment: comment, ecoCommentPublished: isPublished },
    });

    let nominatedVetoSuccess;

    if (submission.nominatedVeto !== hasVeto) {
      nominatedVetoSuccess = await Submission.setNominatedVeto({
        jurorGroupId: state?.jurorGroupId || jurorGroupId,
        submissionId: submission.submissionId,
        value: hasVeto,
      });
    }

    const updatedSubmission = { ...submission };

    if (ecoCommentSuccess) {
      updatedSubmission.ecoComment = comment;
      updatedSubmission.ecoCommentPublished = isPublished;
    }

    if (nominatedVetoSuccess) {
      updatedSubmission.nominatedVeto = hasVeto;
    }

    updateSubmission(updatedSubmission);

    hideModal();
  }

  async function handleDelete() {
    const jurorGroupId =
      !isDetailPage && matchRoles(role, [ROLES.chairPerson, ROLES.staff])
        ? juror.jurorGroups.find(
            (jg) => jg.jurorGroupId === submission?.evaluations[0].jurorGroupId
          )?.jurorGroupId
        : jurorGroup.jurorGroupId;

    const ecoCommentSuccess = await Submission.deleteEcoComment({
      jurorGroupId: state?.jurorGroupId || jurorGroupId,
      submissionId: submission.submissionId,
    });

    let nominatedVetoSuccess;

    if (submission.nominatedVeto) {
      nominatedVetoSuccess = await Submission.setNominatedVeto({
        jurorGroupId: state?.jurorGroupId || jurorGroupId,
        submissionId: submission.submissionId,
        value: false,
      });
    }

    const updatedSubmission = { ...submission };

    if (ecoCommentSuccess) {
      updatedSubmission.ecoComment = null;
      updatedSubmission.ecoCommentPublished = false;
    }

    if (nominatedVetoSuccess) {
      updatedSubmission.nominatedVeto = false;
    }

    updateSubmission(updatedSubmission);

    hideModal();
  }

  if (!isIconVisible) return null;

  return (
    <>
      <EcoCommentModal
        visible={isModalVisible}
        onClose={hideModal}
        comment={submission.ecoComment}
        isPublished={submission.ecoCommentPublished}
        hasVeto={submission.nominatedVeto}
        onSave={handleSave}
        onDelete={handleDelete}
      />
      {isIconVisible && (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon
            className={combine(
              styles.icon,
              hasEcoComment && styles.hasEcoComment,
              isCommentPublished && styles.isPublished,
              hasVeto && styles.hasVeto,
              isDisabled && styles.disabled
            )}
            icon={faLeaf}
            size="2x"
            onClick={showModal}
            role="button"
          />
          {hasVeto && <span className={styles.ecoCommentMarker}>!</span>}
        </div>
      )}
    </>
  );
};

EcoExpert.propTypes = {
  submission: PropTypes.object.isRequired,
  updateSubmission: PropTypes.func.isRequired,
  isDetailPage: PropTypes.bool,
};

export default EcoExpert;
