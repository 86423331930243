import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ROLES, SCOPES } from '../../../config/permissions/roles';
import { isAwardType } from '../../../helpers/awardTypes';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { useConfig } from '../../../hooks/useConfig';
import useQueue from '../../../hooks/useQueue';
import { AWARD_TYPES } from '../../../models/award';
import { SESSION_TYPES } from '../../../models/session';
import Submission from '../../../models/submission';
import PermissionHandler from '../../../modules/permissionHandler';
import ActionsColumn from '../../entriesTable/actionsColumn';
import EvaluationCategory from './evaluationCategory';
import Loading from '../../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';

const JurorEvaluation = ({
  submission,
  updateSubmissions,
  onEvaluationChange,
  isReadOnly,
  impersonatedJuror,
}) => {
  const { jurorId: impersonatingJurorId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [scoreDifference, setScoreDifference] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { jurorGroup, role, session } = useJuror();
  const { t } = useTranslation();
  const { state } = useLocation();
  const {
    EVALUATION_CRITERIA_ORDER,
    ENTRY_DETAIL: { ENABLE_SCORE_TRANSFER }, // Score transfer is disabled for all awards. It will be reintroduced with 2025 iFDA revision.
  } = useConfig();
  const { enqueue } = useQueue();

  const canTransferScores =
    role !== ROLES.staff &&
    ((role !== ROLES.chairPerson &&
      !impersonatingJurorId &&
      !submission.chairPersonMustDecide) ||
      (role === ROLES.chairPerson && submission.chairPersonMustDecide));

  const hideScores =
    submission?.chairPersonMustDecide &&
    role != ROLES.chairPerson &&
    !impersonatingJurorId;

  const maxPoints = submission?.maxPointsPerCriterion || '50';

  const score = useMemo(() => {
    if (!submission || submission.points.length === 0) return 0;

    return submission.points.reduce(
      (sum, criterion) =>
        criterion.points === null ? sum : sum + criterion.points,
      0
    );
  }, [submission]);

  const preselectionScore =
    submission.scoresFromPriorSessions?.[0]?.finalPoints || '-';

  const lastSessionAverage = useMemo(() => {
    if (!submission?.scoresFromPriorSessions?.length) {
      return null;
    }

    const lastSession =
      submission.scoresFromPriorSessions[
        submission.scoresFromPriorSessions.length - 1
      ];

    if (lastSession?.totalEvaluationPoints === null) {
      return null;
    }

    return Math.round(
      lastSession.totalEvaluationPoints / lastSession.numDecidedEvaluations
    );
  }, []);

  useEffect(() => {
    if (
      session?.sessionType !== SESSION_TYPES.FINAL_JURY ||
      lastSessionAverage === null ||
      submission.points.some((criteria) => criteria.points === null)
    ) {
      return;
    }

    setScoreDifference(Math.abs(lastSessionAverage - score));
  }, [score, lastSessionAverage, submission, session]);

  const sortedCategories = useMemo(() => {
    return [...submission.points].sort(
      (a, b) =>
        EVALUATION_CRITERIA_ORDER.indexOf(a.criterion.name.toLowerCase()) -
        EVALUATION_CRITERIA_ORDER.indexOf(b.criterion.name.toLowerCase())
    );
  }, [submission]);

  const handleEvaluationChange = (points, criterionId) => {
    const pointsInt = parseInt(points);

    onEvaluationChange(pointsInt, criterionId);
    debounceChangeHandler(pointsInt, criterionId);
  };

  const updateEvaluation = (points, criterionId) => {
    enqueue(async () => {
      await Submission.updateEvaluation(
        state?.jurorGroupId || jurorGroup.jurorGroupId,
        submission.submissionId,
        criterionId,
        points,
        impersonatingJurorId
      );
    });
  };

  const debounceChangeHandler = useCallback(
    debounce(updateEvaluation, 300),
    []
  );

  const getScoreFromPriorSessionByCriterion = (criterion) => {
    const criterionFromPriorSession =
      submission?.scoresFromPriorSessions?.[0]?.criteria?.find(
        (criteria) => criteria.name === criterion
      );

    const numDecidedEvaluations =
      submission?.scoresFromPriorSessions?.[0]?.numDecidedEvaluations;

    if (
      !criterionFromPriorSession ||
      isNaN(criterionFromPriorSession.totalPoints) ||
      isNaN(numDecidedEvaluations) ||
      numDecidedEvaluations === 0
    )
      return null;

    return Math.round(
      criterionFromPriorSession.totalPoints / numDecidedEvaluations
    );
  };

  const handleTransferScoresClick = async () => {
    setIsLoading(true);
    const transferedScores = await Submission.transferPointsFromPreselection(
      submission,
      jurorGroup.jurorGroupId,
      impersonatedJuror?.jurorId
    );

    transferedScores?.forEach((criterionScore) => {
      onEvaluationChange(criterionScore.score, criterionScore.id);
    });

    setIsLoading(false);

    return;
  };

  return (
    <div className={styles.evaluationWrapper}>
      {scoreDifference > session.scoreAlertCThreshold && (
        <Alert
          variant="danger"
          dismissible
          className={styles.scoreAlert}
          onClose={() => setScoreDifference(0)}
        >
          {t('evaluation.priorSessionScoreAlert', {
            amount: scoreDifference,
          })}
        </Alert>
      )}
      <Container>
        <Row>
          <Col className={styles.titleWrapper}>
            <div className={styles.evaluationTitle}>
              <h2 className={styles.heading}>{t('evaluation.title')}</h2>
              {impersonatedJuror && (
                <span className={styles.impersonationInfo}>
                  {impersonatedJuror.firstname} {impersonatedJuror.lastname}
                </span>
              )}
            </div>

            <div className={styles.rightColum}>
              <ActionsColumn
                submission={submission}
                updateSubmission={updateSubmissions}
                isReadOnly={isReadOnly}
                isDetailPage
              />
              {isAwardType(AWARD_TYPES.IF_DA, session) &&
                session?.sessionType === SESSION_TYPES.FINAL_JURY && (
                  <>
                    <div
                      className={combine(
                        styles.totalScore,
                        styles.preselection
                      )}
                    >
                      <span className={styles.label}>
                        {t('evaluation.preselection')}
                      </span>
                      <span className={styles.score}>{preselectionScore}</span>
                    </div>
                    <div className={styles.transferScoresButtonContainer}>
                      {ENABLE_SCORE_TRANSFER && preselectionScore !== '-' && (
                        <FontAwesomeIcon
                          className={combine(
                            styles.transferScoresButton,
                            !canTransferScores && styles.disabled,
                            isLoading && styles.disabled
                          )}
                          icon={faArrowRight}
                          size="2x"
                          onClick={handleTransferScoresClick}
                        />
                      )}
                    </div>
                  </>
                )}
              <div className={styles.totalScore}>
                <span className={styles.label}>
                  {t('evaluation.totalScore')}
                </span>
                {hideScores ? (
                  <FontAwesomeIcon icon={faEyeSlash} size="2x" color="white" />
                ) : (
                  <span className={styles.score}>{score}</span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <PermissionHandler
        scopes={[SCOPES.canEvaluateSubmission, SCOPES.staff]}
        errorProps={{ style: { pointerEvents: 'none' } }}
      >
        <ul
          className={combine(
            styles.evaluationList,
            submission.chairPersonMustDecide &&
              role != ROLES.chairPerson &&
              styles.disabled,
            !submission.chairPersonMustDecide &&
              role == ROLES.chairPerson &&
              styles.disabled,
            session?.prizeMoneyModeEnabled && styles.disabled,
            isReadOnly && styles.disabled
          )}
        >
          <>
            {isLoading && (
              <div className={styles.loadingOverlay}>
                <Loading />
              </div>
            )}
            {sortedCategories.map((item, index) => (
              <EvaluationCategory
                key={item.criterion.id}
                category={item}
                text={
                  submission?.fields[`${item.criterion.name.toLowerCase()}`] ||
                  item.criterion.description ||
                  ''
                }
                isActive={
                  index === selectedCategory ||
                  (!submission.chairPersonMustDecide &&
                    role == ROLES.chairPerson) ||
                  hideScores
                }
                maxPoints={maxPoints}
                onCategoryClick={(setActive) => {
                  setSelectedCategory(setActive ? index : -1);
                }}
                handleEvaluationChange={handleEvaluationChange}
                pointsStep={submission?.pointsStep}
                scoreFromPriorSession={getScoreFromPriorSessionByCriterion(
                  item?.criterion?.name
                )}
                disabled={role == ROLES.staff}
                hideScores={hideScores}
              />
            ))}
          </>
        </ul>
      </PermissionHandler>
    </div>
  );
};

JurorEvaluation.propTypes = {
  submission: PropTypes.object,
  onEvaluationChange: PropTypes.func,
  updateSubmissions: PropTypes.func,
  isReadOnly: PropTypes.bool,
  impersonatedJuror: PropTypes.object,
};

JurorEvaluation.defaultProps = {
  submission: null,
  onEvaluationChange: () => null,
  updateSubmissions: () => null,
  isReadOnly: false,
  impersonatedJuror: null,
};

export default JurorEvaluation;
