import { useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';

// You can enqueue Promises and they will be executed one by one
// While the promises are being executed, a loading toast will be shown
const useQueue = () => {
  const [queue, setQueue] = useState([]);
  const currentRequest = useRef(null);
  const toastRef = useRef(null);

  useEffect(() => {
    const execute = async () => {
      const [item] = queue;
      currentRequest.current = item.id;
      await item.promise();
      setQueue((prev) => prev.slice(1));
      currentRequest.current = null;
    };

    if (queue.length && !currentRequest.current) {
      execute();
      console.log(toastRef.current);
      if (!toastRef.current) {
        toastRef.current = toast.loading('Saving...', {
          position: 'bottom-right',
        });
      }
    }

    if (!queue.length && toastRef.current) {
      toast.dismiss(toastRef.current);
      toastRef.current = null;
    }
  }, [queue, currentRequest, toastRef, setQueue]);

  const enqueue = (item) =>
    setQueue((prev) => [...prev, { promise: item, id: Math.random() }]);

  return { queue, enqueue };
};

export default useQueue;
