import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './styles.module.scss';

const Checkbox = ({
  icon,
  label,
  name,
  onChange,
  tooltip,
  value,
  readOnly,
}) => {
  const hasTooltip = tooltip !== null;

  const renderTooltip = (props) => (
    <Tooltip id={`${label}__tooltip`} className={styles.tooltip} {...props}>
      {tooltip}
    </Tooltip>
  );

  return (
    <Form.Check className={styles.checkbox} id={`check-${name}`}>
      <Form.Check.Input
        name={name}
        checked={value}
        className={styles.input}
        onChange={onChange}
        type="checkbox"
        disabled={readOnly}
      />
      <Form.Check.Label>
        <span className={styles.label}>{label}</span>
        {icon &&
          (hasTooltip ? (
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
              placement="top"
            >
              <span className={styles.icon}>
                <FontAwesomeIcon icon={icon} />
              </span>
            </OverlayTrigger>
          ) : (
            <span className={styles.icon}>
              <FontAwesomeIcon icon={icon} />
            </span>
          ))}
      </Form.Check.Label>
    </Form.Check>
  );
};

Checkbox.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
  value: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  icon: null,
  label: '',
  name: '',
  onChange: () => null,
  tooltip: null,
  value: false,
  readOnly: false,
};

export default Checkbox;
