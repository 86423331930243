import React, { useEffect, useState, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Dropdown from '../../../../components/dropdown';
import EvaluationEntries from '../../../../components/evaluationEntries';
import { AWARD_TYPES } from '../../../../models/award';
import Session from '../../../../models/session';
import ThresholdCalcSubmissions from '../../../../models/thresholdCalculatorSubmissions';
import Hero from '../../../../modules/hero';
import styles from './styles.module.scss';

const ThresholdCalculator = () => {
  const [submissions, setSubmissions] = useState([]);
  const [submissionsLoading, setSubmissionsLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const { t } = useTranslation();
  const { state } = useLocation();

  const currentSession = sessions?.find((s) => s.sessionId == session);

  const maxPointsForSession = useMemo(() => {
    const selectedSession = sessions.find((s) => s.sessionId == session);

    return selectedSession
      ? selectedSession.maxPointsPerCriterion * selectedSession.criteria.length
      : 0;
  }, [sessions, session]);

  const sessionOptions = useMemo(() => {
    return sessions.map((session) => ({
      value: session.sessionId,
      text: session.sessionName,
    }));
  }, [sessions]);

  useEffect(() => {
    async function fetchSessions() {
      const sessionData = await Session.fetchAll({ archived: false });

      setSessions(sessionData);

      if (state.sessionId) {
        setSession(state.sessionId);
      }
    }

    fetchSessions();
  }, []);

  useEffect(() => {
    async function fetchSubmissions() {
      if (session) {
        setSubmissionsLoading(true);
        const data =
          await ThresholdCalcSubmissions.fetchAllSubmissions(session);
        setSubmissions(data);
        setSubmissionsLoading(false);
      }
    }

    fetchSubmissions();
  }, [session]);

  const handleSessionChange = (val) => {
    setSession(val);
  };

  return (
    <div className={styles.sessionsOverview}>
      <Hero
        title={t('sessions.threshold.title')}
        rightSection={
          <Dropdown
            options={sessionOptions}
            value={session}
            onChange={handleSessionChange}
            label={t('jurors.overview.dropdownLabel')}
            className={'w-100'}
          />
        }
      />
      {currentSession?.awardType === AWARD_TYPES.IF_SIP ? (
        <div className={styles.notAvailableContainer}>
          <h2>{t('sessions.threshold.notAvailable')}</h2>
        </div>
      ) : (
        <Container className={'py-5'}>
          <EvaluationEntries
            submissions={submissions}
            maxPoints={maxPointsForSession}
            loading={submissionsLoading}
            session={currentSession}
            onEntryClick={() => null}
          />
        </Container>
      )}
    </div>
  );
};

export default ThresholdCalculator;
