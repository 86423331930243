import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import EntriesTable from '../../../components/entriesTable';
import Pill from '../../../components/pill';
import SearchField from '../../../components/searchField';
import SortDropdown from '../../../components/sortDropdown';
import { ROLES } from '../../../config/permissions/roles';
import { isAwardType } from '../../../helpers/awardTypes';
import { useJuror } from '../../../hooks/useAuth';
import useSubmissionsFilter from '../../../hooks/useSubmissionsFilter';
import { AWARD_TYPES } from '../../../models/award';
import { EVALUATION_STATUS } from '../../../models/submission';
import SubmissionOverview from '../../../models/submissionOverview';
import Hero from '../../../modules/hero';
import { matchRoles } from '../../../helpers/roles';
import styles from './styles.module.scss';
import { SESSION_TYPES } from '../../../models/session';

const filterDecidedEvaluations = (evaluation) =>
  evaluation.status === EVALUATION_STATUS.decided ||
  evaluation.status === EVALUATION_STATUS.decidedByChairperson;

const Entries = () => {
  const [submissions, setSubmissions] = useState([]);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const [visibleSubmissions, setVisibleSubmissions] = useState([]);
  const [entriesLoading, setEntriesLoading] = useState(true);
  const [searchParams, _] = useSearchParams();
  const { juror, jurorGroup, role, session } = useJuror();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    filterParams,
    filterOptions,
    handleSortChange,
    handleFilterReset,
    handleFilterDeselect,
    onFulltextSearch,
    onSort,
  } = useSubmissionsFilter({ submissions, setVisibleSubmissions });
  const [searchTerm, setSearchTerm] = useState(filterParams?.search);

  useEffect(() => {
    if (
      visibleSubmissions.length &&
      state?.submissionId &&
      !hasScrolledIntoView
    ) {
      // use timeout to allow rendering
      setTimeout(() => {
        const node = document.querySelector(
          `#submission_${state.submissionId}`
        );
        if (node) {
          node.scrollIntoView({ behavior: 'smooth' });
          setHasScrolledIntoView(true);
        }
      }, 100);
    }
  }, [visibleSubmissions, setHasScrolledIntoView]);

  useEffect(() => {
    if (!juror?.jurorId || !jurorGroup?.jurorGroupId || !session) return;

    async function fetchData() {
      setEntriesLoading(true);
      setSubmissions([]);

      let data = [];

      if (
        isAwardType(AWARD_TYPES.IF_SIP, session) &&
        session?.prizeMoneyModeEnabled
      ) {
        data = await SubmissionOverview.fetchAllSubmissionsForSession({
          sessionId: session.sessionId,
        });
      } else {
        if (role === ROLES.chairPerson || role === ROLES.staff) {
          data = (
            await Promise.all(
              juror?.jurorGroups.map((group) =>
                SubmissionOverview.fetchAllSubmissionsForJurorGroup({
                  jurorGroupId: group.jurorGroupId,
                  extraFields: ['presentationForm'],
                  criteriaScores:
                    matchRoles(role, [ROLES.staff]) &&
                    isAwardType(AWARD_TYPES.IF_DA, session) &&
                    session?.sessionType === SESSION_TYPES.FINAL_JURY,
                })
              )
            )
          ).flat();
        } else {
          data = await SubmissionOverview.fetchAllSubmissionsForJurorGroup({
            jurorGroupId: jurorGroup?.jurorGroupId,
            extraFields: ['presentationForm'],
            criteriaScores:
              matchRoles(role, [ROLES.staff]) &&
              isAwardType(AWARD_TYPES.IF_DA, session) &&
              session?.sessionType === SESSION_TYPES.FINAL_JURY,
          });
        }
      }
      data = data.map((submission) => {
        let mappedSubmission = submission;

        if (
          isAwardType(AWARD_TYPES.IF_DA, session) &&
          session?.sessionType === SESSION_TYPES.FINAL_JURY &&
          role === ROLES.chairPerson
        ) {
          const suggestedForNominationExternal = submission?.evaluations?.some(
            (evaluation) =>
              evaluation?.suggestedForNomination &&
              evaluation?.jurorId !== juror?.jurorId
          );
          mappedSubmission.suggestedForNominationExternal =
            suggestedForNominationExternal;
        }

        return mappedSubmission;
      });

      setSubmissions(data);
      setEntriesLoading(false);
    }

    fetchData();
  }, [juror, jurorGroup, session]);

  const handleSubmissionUpdate = (updatedSubmission, updatedKey) => {
    setSubmissions((prev) =>
      prev.map((submission) =>
        submission.submissionId === updatedSubmission.submissionId
          ? updatedSubmission
          : submission
      )
    );
  };

  const handleSortChangeClick = (value) => {
    handleSortChange(value);
  };

  const navigateToDetail = (path, submission, extraState) => {
    const jurorGroupId = submission?.evaluations[0]?.jurorGroupId;
    const params = searchParams ? '?' + searchParams : '';
    let state = {
      submissionsList: visibleSubmissions,
    };

    if (
      isAwardType(AWARD_TYPES.IF_SIP, session) &&
      session.prizeMoneyModeEnabled
    ) {
      state = {
        ...state,
        ...extraState,
      };
    }

    if (
      isAwardType(AWARD_TYPES.IF_DA, session) &&
      matchRoles(role, [ROLES.chairPerson, ROLES.staff])
    ) {
      // Check if we will navigate to an impersonated detail page
      if (path.match(/..\/entry\/[0-9]+\/[0-9]+/)) {
        // Only include submissions from the impersonated jurorGroup to not have
        // unexpected behaviour while switching jurorGroups with navigation.
        state.submissionsList = state.submissionsList.filter(
          (s) =>
            s?.evaluations?.[0]?.jurorGroupId ===
            submission?.evaluations?.[0]?.jurorGroupId
        );
      }

      state = {
        ...state,
        jurorGroupId,
      };
    }

    navigate(location.pathname, {
      replace: true,
      state: {
        submissionId: path.split('/').pop(),
      },
    });
    navigate(path.replace(/\s/g, '') + params, {
      state,
    });
  };

  const debounceFullTextSearch = useCallback(
    debounce(onFulltextSearch, 300),
    []
  );

  const onSearch = (val) => {
    setSearchTerm(val);
    debounceFullTextSearch(val);
  };

  return (
    <div className={styles.entries}>
      <Hero
        title={
          <Trans
            i18nKey="jurorEntries.title"
            components={{ bold: <b /> }}
            values={{
              numEntries: visibleSubmissions.length,
            }}
          />
        }
      />
      <Container fluid="xl">
        <div className={styles.toolbar}>
          <div className={styles.filterPills}>
            {filterParams.data.map((filterParam) => (
              <Pill
                key={filterParam}
                handleClick={() => handleFilterDeselect(filterParam)}
                text={
                  filterParam.split('__')[1] ||
                  filterOptions.find((item) => item.key === filterParam)?.text
                }
              />
            ))}
            {filterParams.order && (
              <Pill
                handleClick={() => handleFilterReset('order')}
                text={t(`jurorEntries.filter.order.${filterParams.order}`)}
              />
            )}
            <button
              className={styles.resetFilter}
              disabled={!filterParams?.data.length}
              onClick={() => handleFilterReset('filterBy')}
            >
              {t(`jurorEntries.filter.reset`)}
            </button>
          </div>
          <SearchField value={searchTerm} onChange={onSearch} />
          <SortDropdown
            activeSortOption={filterParams.order}
            activeOptions={filterParams.data}
            options={filterOptions}
            onChange={handleSortChangeClick}
          />
        </div>
        <div className={styles.tableContainer}>
          <EntriesTable
            submissions={visibleSubmissions}
            updateSubmission={handleSubmissionUpdate}
            loading={entriesLoading}
            onEntryClick={navigateToDetail}
            onSort={onSort}
          />
        </div>
      </Container>
    </div>
  );
};

export default Entries;
