import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { combine } from '../../../helpers/styles';
import { ECO_QUESTIONS } from '../../../config/ecoQuestions';
import styles from './styles.module.scss';

const EntryEcoQuestions = ({ submission }) => {
  const ref = useRef();
  const [maxHeight, setMaxHeight] = useState('103px');
  const [isActive, setIsActive] = useState(true);
  const { t } = useTranslation();
  const ecoQuestionKeys = Object.keys(submission?.fields)
    .filter((key) => key.includes('EcoQuestion'))
    .sort();

  useEffect(() => {
    if (!ref.current) return;

    setMaxHeight(isActive ? `${ref.current.clientHeight + 113}px` : '103px');
  }, [isActive]);

  const containerStyle = {
    transition: 'max-height 0.75s ease-in-out',
    maxHeight: maxHeight,
  };

  if (!ecoQuestionKeys.length) return null;

  return (
    <div
      className={combine(styles.outerContainer, isActive && styles.active)}
      onClick={() => {
        setIsActive((prev) => !prev);
      }}
    >
      <Container className={styles.container} style={containerStyle}>
        <Row>
          <Col className={styles.titleContainer}>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={combine(styles.icon, isActive && styles.rotated)}
            />
            <h2 className={styles.titleText}>{t('ecoQuestions.title')}</h2>
          </Col>
        </Row>

        <div ref={ref} className={styles.questionsContainer}>
          {ecoQuestionKeys.map((key, idx) => {
            const ecoQuestion = ECO_QUESTIONS.find(
              (ecoQuestion) => ecoQuestion.fieldname === key
            );

            let answers = ecoQuestion.answers.map((answer) => ({
              value: answer,
              selected: submission.fields[key].includes(answer),
            }));

            // If "Other" is in answers array, there is a free text answer
            if (submission.fields[key].includes('Other')) {
              const freeTextAnswer = submission.fields[key].find(
                (answer) => !ecoQuestion.answers.includes(answer)
              );

              answers = answers.map((answer) => {
                if (answer.value !== 'Other') return answer;

                return {
                  ...answer,
                  value: `${answer.value}: ${freeTextAnswer}`,
                };
              });
            }

            return (
              <>
                <Row key={key} className={styles.questionContainer}>
                  <Col xs={12} className={styles.question}>
                    <h3 className={styles.questionTitle}>
                      {ecoQuestion.question}
                    </h3>
                  </Col>
                  <Col xs={12}>
                    {answers.map((answer) => {
                      return (
                        <Row
                          key={answer}
                          className={styles.answerOuterContainer}
                        >
                          <Col
                            xs={12}
                            className={styles.answerContainer}
                            style={{
                              opacity: answer.selected ? 1 : 0.5,
                            }}
                          >
                            <div
                              className={combine(
                                styles.checkbox,
                                answer.selected && styles.checked
                              )}
                            >
                              {answer.selected && (
                                <FontAwesomeIcon
                                  className={styles.checkIcon}
                                  icon={faCheck}
                                  color={'white'}
                                />
                              )}
                            </div>
                            <span className={styles.answerText}>
                              {answer.value}
                            </span>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
                {ecoQuestionKeys.length - 1 !== idx && (
                  <div className={styles.separator}></div>
                )}
              </>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

EntryEcoQuestions.propTypes = {
  submission: PropTypes.object,
};

export default EntryEcoQuestions;
