import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faLeaf } from '@fortawesome/pro-light-svg-icons';
import { useJuror } from '../../../../../hooks/useAuth';
import { ROLES } from '../../../../../config/permissions/roles';
import { matchRoles } from '../../../../../helpers/roles';
import Button, { ButtonSizes, ButtonTypes } from '../../../../button';
import Checkbox from '../../../../checkbox';
import Dialog from '../../../../dialog';
import styles from './styles.module.scss';

const EcoCommentModal = ({
  visible,
  comment: initialComment,
  isPublished: initialIsPublished,
  hasVeto: initialHasVeto,
  onClose,
  onDelete,
  onSave,
}) => {
  const [comment, setComment] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [hasVeto, setHasVeto] = useState(false);
  const { t } = useTranslation();
  const { role } = useJuror();

  const isReadOnly = !matchRoles(role, [ROLES.staff]);

  useEffect(() => {
    setComment(initialComment);
    setIsPublished(initialIsPublished);
    setHasVeto(initialHasVeto);
  }, [visible, initialComment, initialIsPublished, initialHasVeto]);

  function handleCommentChange(event) {
    setComment(event.target.value);
  }

  function toggleVeto() {
    setHasVeto((prev) => !prev);
  }

  function togglePublished() {
    setIsPublished((prev) => !prev);
  }

  function handleDelete() {
    onDelete();
  }

  function handleSave() {
    onSave({ comment, isPublished, hasVeto });
  }

  if (!visible) return null;

  return (
    <Dialog
      title={t('jurorEntries.table.columns.actions.ecoComment.dialog.title')}
      titleIcon={faLeaf}
      onClose={onClose}
      noNav
      body={
        <div>
          <textarea
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            readOnly={isReadOnly}
            disabled={false}
          />
          <div className={styles.checkboxContainer}>
            <Checkbox
              label={t(
                'jurorEntries.table.columns.actions.ecoComment.dialog.checkbox.published'
              )}
              name="published"
              value={isPublished}
              onChange={togglePublished}
              readOnly={isReadOnly}
            />
            <Checkbox
              label={t(
                'jurorEntries.table.columns.actions.ecoComment.dialog.checkbox.veto'
              )}
              name="veto"
              value={hasVeto}
              onChange={toggleVeto}
              readOnly={isReadOnly}
            />
          </div>
        </div>
      }
      footer={
        <div className={styles.footerButtonContainer}>
          <Button
            text={t(
              'jurorEntries.table.columns.actions.ecoComment.dialog.button.delete'
            )}
            type={ButtonTypes.TERTIARY}
            onClick={handleDelete}
            size={ButtonSizes.LARGE}
            disabled={isReadOnly}
          />
          <div className={styles.rightButtonGroup}>
            <Button
              text={t(
                'jurorEntries.table.columns.actions.ecoComment.dialog.button.close'
              )}
              type={ButtonTypes.SECONDARY}
              onClick={onClose}
              size={ButtonSizes.LARGE}
            />
            <Button
              text={t(
                'jurorEntries.table.columns.actions.ecoComment.dialog.button.save'
              )}
              type={ButtonTypes.PRIMARY}
              onClick={handleSave}
              size={ButtonSizes.LARGE}
              disabled={isReadOnly}
            />
          </div>
        </div>
      }
    />
  );
};

EcoCommentModal.propTypes = {
  visible: PropTypes.bool,
  comment: PropTypes.string,
  isPublished: PropTypes.bool,
  hasVeto: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};

export default EcoCommentModal;
