import { faCircleInfo, faClock } from '@fortawesome/pro-light-svg-icons';
import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../../../components/button';
import Checkbox from '../../../components/checkbox';
import Datepicker from '../../../components/datepicker';
import Dropdown from '../../../components/dropdown';
import Input from '../../../components/input';
import JurorGroup from '../../../models/jurorGroup';
import Meeting from '../../../models/meeting';
import styles from './styles.module.scss';

const combineDateAndTime = (date, time) => {
  const extractedDate = moment.parseZone(date).format('YYYY-MM-DD');
  const dateAndTime = moment
    .parseZone(extractedDate + ' ' + time)
    .toISOString();
  return dateAndTime;
};

const MeetingDetailForm = ({ meeting, sessionId, onCancel, onSubmit }) => {
  const [jurorGroupOptions, setJurorGroupOptions] = useState([]);
  const { t } = useTranslation();
  const type = meeting ? 'edit' : 'add';

  useEffect(() => {
    JurorGroup.fetchAll(sessionId).then((jurorGroups) => {
      setJurorGroupOptions([
        { value: 0, text: 'Everyone' },
        ...jurorGroups.map((jurorGroup) => ({
          value: jurorGroup.jurorGroupId,
          text: jurorGroup.jurorGroupName,
        })),
      ]);
    });
  }, [sessionId]);

  const formik = useFormik({
    initialValues: {
      mandatory: meeting?.mandatory || false,
      name: meeting?.name || '',
      startDate: meeting?.startDate || '',
      startTime: meeting
        ? moment.parseZone(meeting.startDate).format('HH:mm')
        : '',
      endDate: meeting?.endDate || '',
      endTime: meeting ? moment.parseZone(meeting.endDate).format('HH:mm') : '',
      url: meeting?.url || '',
      jurorGroupId: meeting?.jurorGroupId || '',
    },
    onSubmit: (values) => {
      values.startDate = combineDateAndTime(values.startDate, values.startTime);
      values.endDate = combineDateAndTime(values.endDate, values.endTime);
      values.mandatory = !!values.mandatory;
      delete values.startTime;
      delete values.endTime;

      if(values.jurorGroupId == 0) {
        delete values.jurorGroupId;
      }
      if (meeting?.id) {
        values.id = meeting.id;
      }
      onSubmit(Meeting.from(values));
    },
  });

  const handleDateChange = (field, date) => {
    formik.setFieldValue(field, date.value);
  };

  const handleTimeChange = (field, e) => {
    formik.setFieldValue(field, e.target.value);
  };

  return (
    <div className={styles.meetingForm}>
      <form onSubmit={formik.handleSubmit}>
        <Row className="justify-content-md-center">
          <Col md={7}>
            <h2>
              <Trans
                i18nKey={`sessions.meetings.form.title.${type}`}
                components={{
                  bold: <b />,
                }}
              />
            </h2>
          </Col>
          <Col md={5} className={styles.checkBoxContainer}>
            <Checkbox
              label={t('sessions.meetings.form.inputs.mandatory')}
              name="mandatory"
              value={!!formik.values.mandatory}
              onChange={formik.handleChange}
              tooltip="Test"
              icon={faCircleInfo}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <Dropdown
              name="jurorGroupId"
              label={t('sessions.meetings.form.inputs.group')}
              options={jurorGroupOptions}
              value={formik.values.jurorGroupId}
              onRawChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <Input
              name="name"
              label={t('sessions.meetings.form.inputs.title')}
              required
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={2} className={styles.dateTimeLabel}>
            <span>{t('sessions.meetings.form.inputs.starts')}</span>
          </Col>
          <Col md={5}>
            <Datepicker
              label={t('sessions.meetings.form.inputs.date')}
              required
              value={formik.values.startDate}
              onChange={(v) => {
                handleDateChange('startDate', v);
              }}
            />
          </Col>
          <Col md={5}>
            <Input
              className={styles.timeInput}
              label={t('sessions.meetings.form.inputs.time')}
              icon={faClock}
              required
              value={formik.values.startTime}
              type="time"
              onChange={(v) => {
                handleTimeChange('startTime', v);
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={2} className={styles.dateTimeLabel}>
            <span>{t('sessions.meetings.form.inputs.ends')}</span>
          </Col>
          <Col md={5}>
            <Datepicker
              label={t('sessions.meetings.form.inputs.date')}
              required
              value={formik.values.endDate}
              onChange={(v) => {
                handleDateChange('endDate', v);
              }}
            />
          </Col>
          <Col md={5}>
            <Input
              className={styles.timeInput}
              label={t('sessions.meetings.form.inputs.time')}
              icon={faClock}
              required
              value={formik.values.endTime}
              type="time"
              onChange={(v) => {
                handleTimeChange('endTime', v);
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col>
            <Input
              name="url"
              label={t('sessions.meetings.form.inputs.link')}
              value={formik.values.url}
              onChange={formik.handleChange}
              type={"url"}
            />
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${styles.buttonRow} ${styles.addButtons}`}
        >
          <Col md="auto">
            <Button
              className={styles.button}
              text={t('sessions.meetings.form.buttons.cancel')}
              type={ButtonTypes.SECONDARY}
              onClick={onCancel}
              htmlType="button"
            />
          </Col>
          <Col>
            <Button
              className={styles.addButton}
              text={t('sessions.meetings.form.buttons.submit')}
              type={ButtonTypes.PRIMARY}
              htmlType="submit"
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

MeetingDetailForm.propTypes = {
  meeting: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

MeetingDetailForm.defaultProps = {
  meeting: null,
  onCancel: () => null,
  onSubmit: () => null,
};

export default MeetingDetailForm;
