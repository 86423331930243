import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import {
  faHand,
  faMedal,
  faMessageCode,
  faStar,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import iFGoldAwardLogo from '../../../assets/images/iF-Gold.svg';
import { ROLES, SCOPES } from '../../../config/permissions/roles';
import { ADMIN_CONFIG } from '../../../config/renderConfig';
import { isAwardType } from '../../../helpers/awardTypes';
import { combine } from '../../../helpers/styles';
import { useJuror } from '../../../hooks/useAuth';
import { useConfig } from '../../../hooks/useConfig';
import { AWARD_TYPES } from '../../../models/award';
import { JUROR_GROUP_PHASES } from '../../../models/jurorGroup';
import { SESSION_TYPES } from '../../../models/session';
import Submission, { EVALUATION_STATUS } from '../../../models/submission';
import PermissionHandler from '../../../modules/permissionHandler';
import CommentModal from './commentModal';
import EcoExpert from './ecoExpert';
import { matchRoles } from '../../../helpers/roles';
import { displayWarning } from '../../../helpers/error';
import styles from './styles.module.scss';

const ActionsColumn = ({
  submission,
  updateSubmission,
  isAdminView,
  isReadOnly,
  isDetailPage,
}) => {
  const { jurorId: impersonatingJurorId } = useParams();
  const { role, jurorGroup, juror, session, isPreview } = useJuror();
  const [displayCommentModal, setDisplayCommentModal] = useState(false);
  const { ENTRIES_TABLE } = useConfig();
  const { t } = useTranslation();
  const { state } = useLocation();

  const numComments = useMemo(
    () =>
      submission?.evaluations?.reduce(
        (count, evaluation) => count + (evaluation.comment ? 1 : 0),
        0
      ),
    [submission]
  );
  const nominated = submission?.nominated;
  const suggestedForNomination = useMemo(
    () =>
      submission?.suggestedForNomination ||
      (!isAwardType(AWARD_TYPES.IF_DA, session) &&
        submission?.hasJuryStatement) ||
      submission?.suggestedForNominationExternal,
    [submission, session]
  );

  const evaluationStatus = useMemo(() => {
    const currentJurorEvaluation = submission?.evaluations?.find(
      (evaluation) =>
        evaluation.jurorId === submission?.jurorId ||
        evaluation.jurorId === juror?.jurorId
    );
    return currentJurorEvaluation?.status || submission?.evaluationStatus;
  }, [submission]);

  const areCommentsDiscussed = useMemo(() => {
    if (!submission) return false;

    const hasComments = submission?.evaluations?.some(
      (evaluation) => evaluation.comment
    );

    const areAllCommentsDiscussed = submission?.evaluations?.every(
      (evaluation) => {
        if (evaluation.comment) return evaluation.discussed;
        if (!evaluation.comment) return true;
      }
    );

    return hasComments && areAllCommentsDiscussed;
  }, [submission]);

  const showGoldDecidedAction =
    session?.sessionType === SESSION_TYPES.FINAL_JURY &&
    (suggestedForNomination || nominated);

  const hasPublishRestriction =
    session?.nominationPublishRestrictionLimit &&
    (submission?.publishRestriction ||
      submission?.fields?.publishRestriction) &&
    moment(
      submission?.publishRestriction || submission?.fields?.publishRestriction
    ).isSameOrAfter(moment(session.nominationPublishRestrictionLimit));

  const showPublishRestriction =
    hasPublishRestriction && session?.sessionType === SESSION_TYPES.FINAL_JURY;

  const {
    renderNominate,
    renderDelegateChairperson,
    renderComment,
    renderPrizeMoney,
  } = useMemo(() => {
    const ACTIONS = isAdminView
      ? ADMIN_CONFIG.ENTRIES_TABLE.ACTIONS
      : ENTRIES_TABLE.ACTIONS;
    return {
      renderNominate: ACTIONS.includes('nominate'),
      renderDelegateChairperson: ACTIONS.includes('delegateChairperson'),
      renderComment: ACTIONS.includes('comment'),
      renderPrizeMoney:
        ACTIONS.includes('prize-money') ||
        (isAwardType(AWARD_TYPES.IF_SIP, session) &&
          session?.prizeMoneyModeEnabled),
    };
  }, [ENTRIES_TABLE, session]);

  const handleNominate = async () => {
    const jurorGroupId =
      !isDetailPage && role === ROLES.chairPerson
        ? juror.jurorGroups.find(
            (jg) => jg.jurorGroupId === submission?.evaluations[0].jurorGroupId
          )?.jurorGroupId
        : jurorGroup.jurorGroupId;

    return await Submission.setNominated(
      !submission.nominated,
      state?.jurorGroupId || jurorGroupId || jurorGroup.jurorGroupId,
      submission.submissionId
    ).then((isSuccess) => {
      if (!isSuccess) return;
      updateSubmission({
        ...submission,
        nominated: !submission.nominated,
      });
    });
  };

  const suggestForNomination = async () => {
    let jurorGroupId = state?.jurorGroupId ?? jurorGroup.jurorGroupId;
    let jurorId = submission?.jurorId || impersonatingJurorId;
    let externalSuggestion = false;

    // Get jurorGroupId from submission to enable CP suggestForNomination from submissionOverview
    if (!isDetailPage && ROLES.chairPerson === role) {
      jurorGroupId = submission?.evaluations?.[0]?.jurorGroupId;
    }

    if (
      isAwardType(AWARD_TYPES.IF_DA, session) &&
      session?.sessionType === SESSION_TYPES.FINAL_JURY &&
      role === ROLES.chairPerson &&
      submission?.suggestedForNominationExternal
    ) {
      jurorId = submission?.evaluations?.find(
        (evaluation) => evaluation?.role === ROLES.groupLeader
      )?.jurorId;

      if (isDetailPage) {
        jurorId = submission?.suggestedForNominationExternalJurorId;
      }

      externalSuggestion = true;
    }

    await Submission.setSuggestForNomination(
      !suggestedForNomination,
      jurorGroupId,
      submission.submissionId,
      jurorId
    );

    let updatedSubmission = {
      ...submission,
    };

    if (externalSuggestion) {
      updatedSubmission.suggestedForNominationExternal =
        !suggestedForNomination;
    } else {
      updatedSubmission.suggestedForNomination = !suggestedForNomination;
    }

    const updatedEvaluationJurorId = jurorId || juror.jurorId;

    if (!isDetailPage) {
      updatedSubmission.evaluations = updatedSubmission.evaluations.map((e) =>
        e.jurorId === updatedEvaluationJurorId
          ? { ...e, suggestedForNomination: !suggestedForNomination }
          : e
      );
    }

    return updateSubmission(updatedSubmission);
  };

  const handleSuggestNominationClick = async () => {
    if (showPublishRestriction) {
      return displayWarning(
        t('evaluation.publicationRestrictionWarning', {
          entryName: submission?.submissionName,
        })
      );
    }

    if (isAdminView && submission.hasJuryStatement) {
      return setDisplayCommentModal(true);
    }

    if (isAwardType(AWARD_TYPES.IF_SIP, session)) {
      if (submission.hasJuryStatement || submission.suggestedForNomination) {
        await Submission.deleteJuryStatement({
          jurorGroupId: jurorGroup.jurorGroupId,
          submissionId: submission.submissionId,
        });
        await Submission.setSuggestForNomination(
          false,
          jurorGroup.jurorGroupId,
          submission.submissionId
        );
        return updateSubmission({
          ...submission,
          hasJuryStatement: false,
          suggestedForNomination: false,
        });
      } else {
        return setDisplayCommentModal(true);
      }
    }

    if (isAwardType(AWARD_TYPES.IF_DTA, session)) {
      return setDisplayCommentModal(true);
    }

    return suggestForNomination();
  };

  const handleChairPersonMustDecide = async () => {
    return await Submission.setChairPersonMustDecide(
      !submission.chairPersonMustDecide,
      jurorGroup.jurorGroupId,
      submission.submissionId
    ).then(() => {
      updateSubmission({
        ...submission,
        chairPersonMustDecide: !submission.chairPersonMustDecide,
        evaluations: submission.evaluations?.map((evaluation) =>
          juror.jurorId !== evaluation.jurorId
            ? evaluation
            : {
                ...evaluation,
                status: !submission.chairPersonMustDecide
                  ? EVALUATION_STATUS.toBeDecidedByChairperson
                  : EVALUATION_STATUS.toBeDecided,
              }
        ),
      });
    });
  };

  const handlePrizeMoneyNomination = async () => {
    const jurorGroupId = submission.evaluations[0].jurorGroupId;
    await Submission.setNominated(
      !submission.nominated,
      jurorGroupId,
      submission.submissionId
    ).then(() => {
      updateSubmission({
        ...submission,
        nominated: !submission.nominated,
      });
    });
  };

  const addComment = async ({ comment }) => {
    const jurorGroupId =
      !isDetailPage && matchRoles(role, [ROLES.chairPerson, ROLES.staff])
        ? juror.jurorGroups.find(
            (jg) => jg.jurorGroupId === submission?.evaluations[0].jurorGroupId
          )?.jurorGroupId
        : jurorGroup.jurorGroupId;

    await Submission.setComment(
      comment,
      state?.jurorGroupId || jurorGroupId || jurorGroup.jurorGroupId,
      submission.submissionId,
      submission?.jurorId
    ).then(() => {
      // We need to distinguish between detailPage and overviewPage to update the submission with the correct submission schema.
      // The submission object is different in the detailPage and overviewPage.
      if (!isDetailPage) {
        updateSubmission({
          ...submission,
          evaluations: !submission.evaluations.some(
            (evaluation) => evaluation.jurorId === juror.jurorId
          )
            ? [
                ...submission.evaluations,
                {
                  jurorId: juror.jurorId,
                  firstname: juror.firstname,
                  lastname: juror.lastname,
                  role,
                  comment,
                  totalPoints: null,
                },
              ]
            : submission.evaluations.map((evaluation) =>
                evaluation.jurorId === juror.jurorId
                  ? { ...evaluation, comment, discussed: false }
                  : evaluation
              ),
        });
      }
      if (isDetailPage && matchRoles(role, [ROLES.chairPerson])) {
        // The CP has a different submission object on the detail page than other roles.
        // The CP has the evaluations field, that contains all evaluations for an entry.
        // This is needed to display the comments of all jurors on the detail page.
        // In order for this to work properly we need to update the evaluations field with the new comment.
        updateSubmission({
          ...submission,
          evaluations: !submission.evaluations.some(
            (evaluation) => evaluation.jurorId === juror.jurorId
          )
            ? [
                ...submission.evaluations,
                {
                  jurorId: juror.jurorId,
                  firstname: juror.firstname,
                  lastname: juror.lastname,
                  role,
                  discussed: false,
                  comment,
                },
              ]
            : submission.evaluations.map((evaluation) =>
                evaluation.jurorId === juror.jurorId
                  ? { ...evaluation, comment, discussed: false }
                  : evaluation
              ),
          comment,
          discussed: false,
        });
      } else {
        updateSubmission({ ...submission, comment, discussed: false });
      }
    });
  };

  const deleteComment = async () => {
    const jurorGroupId =
      !isDetailPage && role === ROLES.chairPerson
        ? juror.jurorGroups.find(
            (jg) => jg.jurorGroupId === submission?.evaluations[0].jurorGroupId
          )?.jurorGroupId
        : jurorGroup.jurorGroupId;

    await Submission.deleteComment(jurorGroupId, submission.submissionId);

    if (!isDetailPage) {
      updateSubmission({
        ...submission,
        evaluations: submission.evaluations.map((evaluation) =>
          evaluation.jurorId !== juror.jurorId
            ? evaluation
            : { ...evaluation, comment: null }
        ),
        comment: null,
      });
    }
    if (isDetailPage && matchRoles(role, [ROLES.chairPerson])) {
      updateSubmission({
        ...submission,
        evaluations: submission.evaluations.map((evaluation) =>
          evaluation.jurorId === juror.jurorId
            ? { ...evaluation, comment: null }
            : evaluation
        ),
        comment: null,
      });
    } else {
      updateSubmission({
        ...submission,
        comment: null,
      });
    }
  };

  const addJuryStatement = async ({ comment }) => {
    await Submission.setJuryStatement({
      juryStatement: comment,
      jurorGroupId: jurorGroup.jurorGroupId,
      submissionId: submission.submissionId,
    });
    await Submission.setSuggestForNomination(
      true,
      jurorGroup.jurorGroupId,
      submission.submissionId
    );
    return updateSubmission({
      ...submission,
      hasJuryStatement: true,
      suggestedForNomination: true,
    });
  };

  const handleCommentSubmit = (submitData) => {
    if (isAwardType(AWARD_TYPES.IF_DA, session)) {
      addComment(submitData);
    }

    if (
      isAwardType(AWARD_TYPES.IF_SIP, session) ||
      isAwardType(AWARD_TYPES.IF_DTA, session)
    ) {
      addJuryStatement(submitData);
    }

    setDisplayCommentModal(false);
  };

  const handleCommentDelete = async () => {
    if (isAwardType(AWARD_TYPES.IF_DTA, session)) {
      setDisplayCommentModal(false);
      await Submission.deleteJuryStatement({
        jurorGroupId: jurorGroup.jurorGroupId,
        submissionId: submission.submissionId,
      });
      await Submission.setSuggestForNomination(
        false,
        jurorGroup.jurorGroupId,
        submission.submissionId
      );
      return updateSubmission({
        ...submission,
        hasJuryStatement: false,
        suggestedForNomination: false,
      });
    }

    if (isAwardType(AWARD_TYPES.IF_DA, session)) {
      setDisplayCommentModal(false);
      deleteComment();
    }
  };

  const handleSetDiscussed = async () => {
    if (isAwardType(AWARD_TYPES.IF_DA, session)) {
      setDisplayCommentModal(false);
      const success = await Submission.setCommentsDiscussed({
        jurorGroupId: jurorGroup.jurorGroupId,
        submissionId: submission.submissionId,
      });

      if (!success) return;

      if (!isDetailPage) {
        updateSubmission({
          ...submission,
          evaluations: submission.evaluations.map((evaluation) => ({
            ...evaluation,
            discussed: evaluation.comment ? true : evaluation.discussed,
          })),
        });
      } else {
        updateSubmission({
          ...submission,
          evaluations: submission.evaluations.map((evaluation) => ({
            ...evaluation,
            discussed: evaluation.comment ? true : evaluation.discussed,
          })),
          discussed: submission.comment ? true : submission.discussed,
        });
      }

      return;
    }
  };

  const onModalClose = () => {
    setDisplayCommentModal(false);
  };

  const onCommentModalShow = () => {
    setDisplayCommentModal(true);
  };

  const goldDecidedActionDisabled =
    (role !== ROLES.chairPerson && submission.chairPersonMustDecide) ||
    hasPublishRestriction ||
    (session?.sessionType === SESSION_TYPES.FINAL_JURY &&
      role !== ROLES.chairPerson);

  return (
    <>
      <CommentModal
        submission={submission}
        onClose={onModalClose}
        onSubmit={handleCommentSubmit}
        visible={displayCommentModal}
        isAdminView={isAdminView}
        onDelete={handleCommentDelete}
        isDiscussed={areCommentsDiscussed}
        onSetDiscussed={handleSetDiscussed}
      />
      <div
        className={styles.buttonContainer}
        onClick={(e) => e.stopPropagation()}
      >
        {hasPublishRestriction && showPublishRestriction && (
          <span className={styles.publishRestriction}>
            {t('evaluation.publicationRestriction')}
          </span>
        )}
        {renderNominate && (
          <>
            <PermissionHandler
              scopes={[
                SCOPES.canNominateSubmission,
                session?.sessionType === SESSION_TYPES.FINAL_JURY &&
                  SCOPES.chairPerson,
                session?.sessionType === SESSION_TYPES.FINAL_JURY &&
                  SCOPES.staff,
              ]}
            >
              {showGoldDecidedAction && (
                <span className={styles.nominatedWrapper}>
                  <img
                    src={iFGoldAwardLogo}
                    className={combine(
                      styles.nominatedIcon,
                      nominated && styles.active,
                      goldDecidedActionDisabled && styles.disabled,
                      submission.chairPersonMustDecide && styles.disabled,
                      (isReadOnly || isPreview) && styles.disabled
                    )}
                    size="2x"
                    role="button"
                    onClick={handleNominate}
                  />
                </span>
              )}
            </PermissionHandler>

            <PermissionHandler
              scopes={[
                !(
                  isAwardType(AWARD_TYPES.IF_DA, session) &&
                  session?.sessionType === SESSION_TYPES.PRESELECTION
                ) && SCOPES.canSuggestSubmissionForNomination,
                !(
                  isAwardType(AWARD_TYPES.IF_DA, session) &&
                  session?.sessionType === SESSION_TYPES.PRESELECTION
                ) &&
                  isDetailPage &&
                  SCOPES.staff,
                isAwardType(AWARD_TYPES.IF_DTA, session) &&
                  role === ROLES.staff &&
                  SCOPES.staff,
              ]}
            >
              <FontAwesomeIcon
                className={combine(
                  styles.suggestForNominationIcon,
                  suggestedForNomination && styles.active,
                  role !== ROLES.chairPerson &&
                    submission.chairPersonMustDecide &&
                    styles.disabled,
                  role === ROLES.chairPerson &&
                    impersonatingJurorId &&
                    !submission.chairPersonMustDecide &&
                    styles.disabled,
                  isAdminView && !suggestedForNomination && styles.readOnly,
                  !isAdminView &&
                    session?.prizeMoneyModeEnabled &&
                    styles.readOnly,
                  isReadOnly || (isPreview && styles.disabled),
                  showPublishRestriction && styles.publishRestricted
                )}
                size="2x"
                icon={suggestedForNomination ? faStarSolid : faStar}
                role="button"
                onClick={handleSuggestNominationClick}
              />
            </PermissionHandler>
          </>
        )}
        {renderDelegateChairperson && (
          <PermissionHandler
            scopes={[
              SCOPES.canLetChairpersonDecide,
              isDetailPage && impersonatingJurorId && SCOPES.chairPerson,
              isDetailPage && impersonatingJurorId && SCOPES.staff,
            ]}
          >
            <FontAwesomeIcon
              className={combine(
                styles.icon,
                submission.chairPersonMustDecide && styles.active,
                evaluationStatus === EVALUATION_STATUS.decidedByChairperson &&
                  styles.disabled,
                matchRoles(role, [ROLES.chairPerson, ROLES.staff]) &&
                  styles.disabled,
                isReadOnly && styles.disabled
              )}
              size="2x"
              icon={faHand}
              role="button"
              onClick={handleChairPersonMustDecide}
            />
          </PermissionHandler>
        )}
        <EcoExpert
          submission={submission}
          updateSubmission={updateSubmission}
          isDetailPage={isDetailPage}
        />
        {renderComment && !impersonatingJurorId && (
          <div className={styles.commentContainer}>
            <FontAwesomeIcon
              className={combine(
                styles.commentIcon,
                submission.comment && styles.active,
                numComments > 0 && styles.active,
                role !== ROLES.chairPerson &&
                  submission.chairPersonMustDecide &&
                  styles.disabled,
                isReadOnly && styles.disabled,
                areCommentsDiscussed && styles.discussed
              )}
              role="button"
              size="2x"
              icon={faMessageCode}
              onClick={onCommentModalShow}
            />
            {(role !== ROLES.juror ||
              jurorGroup?.jurorGroupPhase ===
                JUROR_GROUP_PHASES.discussionPhase) &&
              numComments > 0 && (
                <span
                  className={combine(
                    styles.badge,
                    role !== ROLES.chairPerson &&
                      submission.chairPersonMustDecide &&
                      styles.disabled,
                    isReadOnly && styles.disabled,
                    areCommentsDiscussed && styles.discussed
                  )}
                  onClick={onCommentModalShow}
                >
                  {numComments}
                </span>
              )}
          </div>
        )}
        {renderPrizeMoney && (
          <FontAwesomeIcon
            className={combine(
              styles.nominatedIcon,
              nominated && styles.active,
              !isAdminView && styles.readOnly,
              isReadOnly && styles.disabled
            )}
            size="2x"
            icon={faMedal}
            role="button"
            onClick={handlePrizeMoneyNomination}
          />
        )}
      </div>
    </>
  );
};

ActionsColumn.propTypes = {
  submission: PropTypes.object,
  updateSubmission: PropTypes.func,
  isAdminView: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isDetailPage: PropTypes.bool,
};

ActionsColumn.defaultProps = {
  submission: {},
  updateSubmission: () => {},
  isAdminView: false,
  isReadOnly: false,
  isDetailPage: false,
};

export default ActionsColumn;
