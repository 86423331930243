import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTypes } from '../../components/button';
import Datepicker from '../../components/datepicker';
import Dropdown from '../../components/dropdown';
import Input from '../../components/input';
import Award, { AWARD_TYPES } from '../../models/award';
import EvaluationCriterion from '../../models/evaluationCriterion';
import Session, { SESSION_TYPES } from '../../models/session';
import styles from './styles.module.scss';

export const SESSION_FORM_TYPES = {
  create: 'create',
  edit: 'edit',
};

const MAX_SCORE_PER_CRITERION = 1000;

const SessionForm = ({
  onSubmit,
  onCancel,
  session,
  withButtons,
  readOnly,
  type,
  whiteFieldBackgrounds,
}) => {
  const { t } = useTranslation();
  const [scoringStepsOptions, setScoringStepsOptions] = useState([]);
  const [awards, setAwards] = useState([]);
  const [awardOptions, setAwardOptions] = useState([]);
  const [predeccessorSessionOptions, setPredecessorSessionOptions] = useState(
    []
  );

  const sessionOptions = useMemo(
    () =>
      Object.values(SESSION_TYPES).map((sessionType) => ({
        value: sessionType,
        text: sessionType,
      })),
    []
  );

  useEffect(() => {
    if (!session) return;
    calcStepsOptions(session.maxPointsPerCriterion);
  }, [session]);

  useEffect(() => {
    Award.fetchAll().then((data) => {
      setAwards(data);
      setAwardOptions(
        data.map((award) => ({
          value: award.awardId,
          text: award.awardName,
        }))
      );
    });
    Session.fetchAll({ archived: false }).then((sessions) => {
      return setPredecessorSessionOptions(
        sessions
          .filter(
            (session) =>
              session.sessionType === SESSION_TYPES.PRESELECTION &&
              moment(session.endDate).isBefore(moment())
          )
          .map((session) => ({
            value: session.sessionId,
            text: session.sessionName,
          }))
      );
    });
  }, [setAwardOptions, setPredecessorSessionOptions]);

  const extendedAwardOptions = useMemo(() => {
    if (awardOptions.length === 0) return [];
    if (!session) return awardOptions;

    const isSessionAwardInOptions = awardOptions.some(
      (option) => option.value === session.awardId
    );

    if (!isSessionAwardInOptions) {
      return [
        { value: session.awardId, text: session.awardName },
        ...awardOptions,
      ];
    }

    return awardOptions;
  }, [awardOptions, session]);

  const formik = useFormik({
    initialValues: session || {
      sessionName: '',
      sessionType: '',
      predecessorSessionId: '',
      awardId: '',
      startDate: '',
      endDate: '',
      maxPointsPerCriterion: '',
      pointsStep: '',
      predecessorSessionId: '',
      nominationPublishRestrictionLimit: '',
    },
    enableReinitialize: true,
    validate: (values) => {
      calcStepsOptions(values.maxPointsPerCriterion);
    },
    onSubmit: async (formData) => {
      // TODO: Remove this, if the backend sets any default criteria.
      const addCriteria = async () => {
        let criteriaNames = [
          'Idea',
          'Form',
          'Function',
          'Differentiation',
          'Sustainability',
        ];

        const awardType = awards.find(
          (award) => award.awardId == formData.awardId
        ).awardType;

        if (awardType === AWARD_TYPES.IF_SIP) {
          criteriaNames = [
            'Problem-solving',
            'Reasonable effort',
            'Moral-ethical standards',
            'Beneficial experiences',
            'Solidarity',
          ];
        }

        if (awardType === AWARD_TYPES.IF_DTA) {
          criteriaNames = [
            'Problem-solving',
            'Moral-ethical standards',
            'Solidarity',
            'Economic Calculation',
            'Beneficial experiences',
          ];
        }

        const criteria = await EvaluationCriterion.fetchAll();
        const filteredCriteria = criteria
          .filter((criterion) =>
            criteriaNames.some((name) => name === criterion.name)
          )
          .map((criterion) => ({ id: criterion.id }));
        formData.criteria = filteredCriteria;
      };

      if (type === SESSION_FORM_TYPES.create) await addCriteria();
      onSubmit({ ...formData, uiConfig: {} });
    },
  });

  const needsPredecessorSession =
    formik.values.sessionType === SESSION_TYPES.FINAL_JURY;

  const handleSessionTypeChange = (e) => {
    if (e.target.value !== SESSION_TYPES.FINAL_JURY) {
      formik.setFieldValue('predecessorSessionId', '');
    }
    formik.setFieldValue('sessionType', e.target.value);
  };

  const calcStepsOptions = (maxPoints) => {
    let array = Array.from(Array(Math.round(maxPoints / 2) + 1).keys());
    setScoringStepsOptions([
      {
        value: 1,
        text: '1',
      },
      ...array
        .filter((v) => v % 5 === 0 && maxPoints % v === 0)
        .map((v) => ({
          value: v,
          text: v.toString(),
        })),
    ]);
  };

  const handleDateChange = (date, fieldName) => {
    formik.setFieldValue(fieldName, date.value);
  };

  const handleMaxPointsChange = (e) => {
    if (
      isNaN(e.target.value) ||
      e.target.value < 0 ||
      e.target.value > MAX_SCORE_PER_CRITERION
    )
      return;
    formik.setFieldValue('maxPointsPerCriterion', e.target.value);
  };

  return (
    <Form className={styles.sessionForm}>
      <Row>
        <Col>
          <Dropdown
            name="awardId"
            required
            label={t('session.form.selectAward')}
            options={extendedAwardOptions}
            value={formik.values.awardId}
            onRawChange={formik.handleChange}
            readOnly={readOnly}
            whiteBackground={whiteFieldBackgrounds}
          />
        </Col>
      </Row>
      {type === SESSION_FORM_TYPES.create && (
        <Row>
          <Col>
            <Input
              required
              label={t('session.form.sessionName')}
              className={whiteFieldBackgrounds ? styles.whiteBackground : ''}
              name="sessionName"
              value={formik.values.sessionName}
              onChange={formik.handleChange}
              readOnly={readOnly}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Dropdown
            required
            label={t('session.form.sessionType')}
            name="sessionType"
            options={sessionOptions}
            value={formik.values.sessionType}
            onRawChange={handleSessionTypeChange}
            readOnly={readOnly}
            whiteBackground={whiteFieldBackgrounds}
          />
        </Col>
      </Row>
      <Row>
        <Col className={!needsPredecessorSession && styles.inactive}>
          <Dropdown
            name="predecessorSessionId"
            label={t('session.form.predecessorSession')}
            options={predeccessorSessionOptions}
            value={formik.values.predecessorSessionId}
            onRawChange={formik.handleChange}
            readOnly={readOnly || !needsPredecessorSession}
            required={needsPredecessorSession}
            whiteBackground={whiteFieldBackgrounds}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label={t('session.form.maxPointsPerCriterion')}
            required
            className={whiteFieldBackgrounds ? styles.whiteBackground : ''}
            name="maxPointsPerCriterion"
            value={formik.values.maxPointsPerCriterion}
            onChange={handleMaxPointsChange}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <Dropdown
            required
            label={t('session.form.scoringSteps')}
            name="pointsStep"
            options={scoringStepsOptions}
            value={formik.values.pointsStep}
            onRawChange={formik.handleChange}
            readOnly={readOnly}
            whiteBackground={whiteFieldBackgrounds}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Datepicker
            required
            label={t('session.form.startDate')}
            className={whiteFieldBackgrounds ? styles.whiteBackground : ''}
            value={formik.values.startDate}
            onChange={(date) => handleDateChange(date, 'startDate')}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <Datepicker
            required
            label={t('session.form.endDate')}
            className={whiteFieldBackgrounds ? styles.whiteBackground : ''}
            value={formik.values.endDate}
            onChange={(date) => handleDateChange(date, 'endDate')}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {formik.values.sessionType === SESSION_TYPES.FINAL_JURY && (
        <Row>
          <Col>
            <Datepicker
              required
              label={t('session.form.publishRestrictionLimit')}
              className={whiteFieldBackgrounds ? styles.whiteBackground : ''}
              value={formik.values.nominationPublishRestrictionLimit}
              onChange={(date) =>
                handleDateChange(date, 'nominationPublishRestrictionLimit')
              }
              readOnly={readOnly}
            />
          </Col>
        </Row>
      )}
      {withButtons && (
        <div className={styles.buttonRow}>
          <Button
            text={t('session.form.cancel')}
            type={ButtonTypes.SECONDARY}
            onClick={() => {
              formik.resetForm();
              onCancel();
            }}
            htmlType="button"
          />
          <Button
            text={t('session.form.submit')}
            type={ButtonTypes.PRIMARY}
            onClick={formik.handleSubmit}
            htmlType="submit"
          />
        </div>
      )}
    </Form>
  );
};

export default SessionForm;

SessionForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  session: PropTypes.object,
  withButtons: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(SESSION_FORM_TYPES)),
  whiteFieldBackgrounds: PropTypes.bool,
};

SessionForm.defaultProps = {
  onSubmit: () => null,
  onCancel: () => null,
  session: null,
  withButtons: true,
  readOnly: false,
  type: SESSION_FORM_TYPES.create,
  whiteFieldBackgrounds: false,
};
