import { forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faCalendar,
} from '@fortawesome/pro-light-svg-icons';
import Button from '../button';
import Input from '../input';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const Datepicker = ({ name, label, onChange, value, readOnly, className }) => {
  const [calendarMonth, setCalendarMonth] = useState(null);
  const parsedDate = value
    ? moment.parseZone(value).set({ hours: 0, minute: 0 })
    : null;
  const convertedValue = value ? new Date(parsedDate.toISOString()) : null;

  const datePickerRef = useRef();

  const handleChange = (date) => {
    onChange({
      name,
      value: moment(date).add(2, 'hours').toISOString(),
    });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      icon={faCalendar}
      label={label}
      className={className || ''}
      required
      onClick={() => {
        !readOnly ? onClick() : null;
      }}
      value={value}
    />
  ));

  const renderCustomContainer = ({ className, children }) => {
    return (
      <>
        <CalendarContainer className={className}>
          <div>{children}</div>
          <div className={styles.footer}>
            <Button
              text="Save"
              className={styles.button}
              onClick={(e) => {
                datePickerRef.current.setOpen(false);
              }}
            />
          </div>
        </CalendarContainer>
      </>
    );
  };

  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    return (
      <div className={styles.header}>
        <button
          className={styles.button}
          onClick={() => {
            setCalendarMonth((prev) => {
              return moment(prev).subtract(1, 'M').format();
            });
            decreaseMonth();
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <span className={styles.label}>{moment(date).format('MMMM yyyy')}</span>
        <button
          className={styles.button}
          onClick={() => {
            setCalendarMonth((prev) => {
              return moment(prev).add(1, 'M').format();
            });
            increaseMonth();
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    );
  };

  const getDayClassName = (day) => {
    const isActiveDay = moment(day).isSame(parsedDate, 'day');
    const outsideOfMonth = !moment(day).isSame(calendarMonth, 'month');
    return combine(
      styles.day,
      isActiveDay && styles.active,
      outsideOfMonth && styles.outside
    );
  };

  const popperModifiers = [
    {
      name: 'offset',
      fn: ({ x, y }) => {
        return { x, y: y - 26 };
      },
    },
  ];

  return (
    <DatePicker
      calendarClassName={styles.calendar}
      calendarContainer={renderCustomContainer}
      calendarStartDay={1}
      customInput={<CustomInput />}
      dateFormat="dd / MM / yyyy"
      dayClassName={getDayClassName}
      onCalendarOpen={() => {
        setCalendarMonth(parsedDate || moment());
      }}
      onChange={handleChange}
      popperModifiers={popperModifiers}
      popperPlacement="bottom-end"
      ref={datePickerRef}
      renderCustomHeader={renderCustomHeader}
      selected={convertedValue}
      shouldCloseOnSelect={false}
      showPopperArrow={false}
      wrapperClassName={styles.wrapper}
    />
  );
};

Datepicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
};

Datepicker.defaultProps = {
  name: '',
  label: '',
  onChange: () => null,
  value: null,
  readOnly: false,
  className: '',
};

export default Datepicker;
