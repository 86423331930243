import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Messages from '../../../components/dashboard/chatWidget/messages';
import Dropdown from '../../../components/dropdown';
import JurorGroup from '../../../models/jurorGroup';
import Session from '../../../models/session';
import Hero from '../../../modules/hero';
import styles from './styles.module.scss';
const StaffChat = () => {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchSessions() {
      await Session.fetchAll({ archived: false }).then((sessions) => {
        const data = sessions.map((session) => ({
          value: session.sessionId,
          text: session.sessionName,
        }));
        setSessions(data);
        setLoading(false);
      });
    }

    fetchSessions();
  }, []);

  useEffect(() => {
    async function fetchGroups() {
      await JurorGroup.fetchAll(selectedSession).then((groups) => {
        const data = groups.map((group) => ({
          value: group.jurorGroupId,
          text: group.jurorGroupName,
        }));
        setGroups(data);
      });
    }

    fetchGroups();
  }, [selectedSession]);

  const handleSessionChange = (session) => {
    setSelectedGroup(null);
    setGroups([]);
    setSelectedSession(session);
  };

  const handleGroupChange = (group) => {
    setSelectedGroup(group);
  };

  return (
    <div className={styles.chat}>
      <Hero
        title={t('admin.chat.title')}
        rightSection={
          <div className={styles.dropdownContainer}>
            <Dropdown
              options={sessions}
              onChange={handleSessionChange}
              value={selectedSession}
              label={t('admin.chat.session')}
              required
              className={styles.dropdown}
            />
            <Dropdown
              options={groups}
              onChange={handleGroupChange}
              value={selectedGroup}
              readOnly={!selectedSession && groups.length}
              label={t('admin.chat.jurorGroup')}
              required
              className={styles.dropdown}
            />
          </div>
        }
      />
      <Container className="py-4">
        {selectedGroup && (
          <Messages
            autoRefresh
            limitMessagesAmount={false}
            jurorGroupId={selectedGroup}
            className={styles.messagesContainer}
            wholePage
          />
        )}
      </Container>
    </div>
  );
};

export default StaffChat;
