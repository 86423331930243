class SubmissionOverview {
  constructor(
    submissionId,
    submissionName,
    submissionPrimaryMediaUrl,
    submissionPrimaryMediaThumbnailUrl,
    categoryName,
    disciplineName,
    extraFields,
    submissionType,
    accountCountryIsoCode,
    scoresFromPriorSessions,
    evaluations,
    bookmarked,
    suggestedForNomination,
    discussInGroup,
    numComments,
    chairPersonMustDecide,
    discussedInGroup,
    nominated,
    hasJuryStatement,
    scoreAlertA,
    scoreAlertB,
    scoreAlertC,
    jurorId,
    totalPointsFromAllDecidedEvaluations,
    publishRestriction,
    ecoComment,
    ecoCommentPublished,
    nominatedVeto,
    finalPoints,
    criteriaScores,
    sustainabilityScore
  ) {
    this.submissionId = submissionId;
    this.submissionName = submissionName;
    this.submissionPrimaryMediaUrl = submissionPrimaryMediaUrl;
    this.submissionPrimaryMediaThumbnailUrl =
      submissionPrimaryMediaThumbnailUrl;
    this.categoryName = categoryName;
    this.disciplineName = disciplineName;
    this.extraFields = extraFields;
    this.submissionType = submissionType;
    this.accountCountryIsoCode = accountCountryIsoCode;
    this.scoresFromPriorSessions = scoresFromPriorSessions;
    this.evaluations = evaluations;
    this.bookmarked = bookmarked;
    this.suggestedForNomination = suggestedForNomination;
    this.discussInGroup = discussInGroup;
    this.numComments = numComments;
    this.chairPersonMustDecide = chairPersonMustDecide;
    this.discussedInGroup = discussedInGroup;
    this.nominated = nominated;
    this.hasJuryStatement = hasJuryStatement;
    this.scoreAlertA = scoreAlertA;
    this.scoreAlertB = scoreAlertB;
    this.scoreAlertC = scoreAlertC;
    this.jurorId = jurorId;
    this.totalPointsFromAllDecidedEvaluations =
      totalPointsFromAllDecidedEvaluations;
    this.publishRestriction = publishRestriction;
    this.ecoComment = ecoComment;
    this.ecoCommentPublished = ecoCommentPublished;
    this.nominatedVeto = nominatedVeto;
    this.finalPoints = finalPoints;
    this.criteriaScores = criteriaScores;
    this.sustainabilityScore = sustainabilityScore;
  }

  static from = (submissionOverview) => {
    if (!submissionOverview) return null;

    return new SubmissionOverview(
      submissionOverview.submissionId,
      submissionOverview.submissionName,
      submissionOverview.submissionPrimaryMediaUrl,
      submissionOverview.submissionPrimaryMediaThumbnailUrl,
      submissionOverview.categoryName,
      submissionOverview.disciplineName,
      submissionOverview.extraFields,
      submissionOverview.submissionType,
      submissionOverview.accountCountryIsoCode,
      submissionOverview.scoresFromPriorSessions,
      submissionOverview.evaluations,
      submissionOverview.bookmarked,
      submissionOverview.suggestedForNomination,
      submissionOverview.discussInGroup,
      submissionOverview.numComments,
      submissionOverview.chairPersonMustDecide,
      submissionOverview.discussedInGroup,
      submissionOverview.nominated,
      submissionOverview.hasJuryStatement,
      submissionOverview.scoreAlertA,
      submissionOverview.scoreAlertB,
      submissionOverview.scoreAlertC,
      submissionOverview.jurorId,
      submissionOverview.totalPointsFromAllDecidedEvaluations,
      submissionOverview.publishRestriction,
      submissionOverview.ecoComment,
      submissionOverview.ecoCommentPublished,
      submissionOverview.nominatedVeto,
      submissionOverview.finalPoints,
      submissionOverview.criteriaScores,
      submissionOverview?.criteriaScores?.find(
        (criterion) => criterion.name === 'Sustainability'
      )?.finalPoints || 0
    );
  };

  static of = (submissions) => {
    return submissions?.map((submission) =>
      SubmissionOverview.from(submission)
    );
  };

  static fetchAllSubmissionsForJurorGroup = async ({
    jurorGroupId,
    extraFields,
    criteriaScores,
  }) => {
    const params = new URLSearchParams();

    if (extraFields) {
      params.append('extraFields', extraFields.join(','));
    }

    if (criteriaScores) {
      params.append('criteriaScores', 'true');
    }

    params.toString();

    return fetch(
      `/if-api/jury-v2/jurorGroup/${jurorGroupId}/submissions${params.toString() ? '?' + params.toString() : ''}`
    )
      .then((response) => response.json())
      .then((submissions) => SubmissionOverview.of(submissions))
      .catch((err) => {
        console.error(err);
      });
  };

  static fetchAllSubmissionsForSession = async ({ sessionId }) => {
    return fetch(`/if-api/jury-v2/session/${sessionId}/submissionsWithGroups`)
      .then((response) => response.json())
      .then((submissions) => SubmissionOverview.of(submissions))
      .catch((err) => {
        console.error(err);
      });
  };
}

export default SubmissionOverview;
