import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth, useJuror } from '../../../hooks/useAuth';
import GroupDropdown from '../groupDropdown';
import { combine } from '../../../helpers/styles';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import PermissionHandler from '../../permissionHandler';
import { ROLES, SCOPES } from '../../../config/permissions/roles';

const ControlSection = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { juror, jurorGroup, session, selectJurorGroup, isLoading } =
    useJuror();
  const { isAuthenticated: isAdmin } = useAuth();

  const handleGroupSelect = (groupId) => {
    selectJurorGroup(groupId);
  };

  const isGroupDropdownVisible =
    (jurorGroup?.role !== ROLES.chairPerson &&
      jurorGroup?.role !== ROLES.staff) ||
    pathname?.match(/\/juror\/dashboard$/);

  return (
    <div className={styles.heroRight}>
      <div className={styles.rightSection}>
        <div className={styles.itemContainer}>
          <span className={styles.title}>
            {t('jurorDashboard.hero.role.title')}
          </span>
          <span className={styles.value}>
            <FontAwesomeIcon icon={faUser} className={styles.icon} />
            {isAdmin ? (
              <span>{t(`roles.STAFF`)}</span>
            ) : (
              <span>{isLoading ? '-' : t(`roles.${jurorGroup?.role}`)}</span>
            )}
          </span>
        </div>
        {isGroupDropdownVisible && (
          <div className={styles.itemContainer}>
            <span className={styles.title}>
              <GroupDropdown
                groups={juror?.jurorGroups}
                selectedGroup={jurorGroup?.jurorGroupId}
                onSelect={handleGroupSelect}
              />
            </span>
            <span className={combine(styles.value, 'placeholder-glow')}>
              {isLoading && <span className={'placeholder'}></span>}
              {jurorGroup?.jurorGroupName || '-'}
            </span>
          </div>
        )}
      </div>
      <PermissionHandler
        scopes={[SCOPES.juror, SCOPES.groupLeader, SCOPES.chairPerson]}
      >
        {!isAdmin && (
          <div className={styles.leftSection}>
            <div className={styles.itemContainer}>
              <span className={styles.title}>
                {t('jurorDashboard.hero.sessionType.title')}
              </span>
              <span className={styles.value}>
                <span className={styles.value}>
                  {isLoading || isAdmin ? (
                    <span className={'placeholder'}></span>
                  ) : (
                    t(`sessionTypes.${session?.sessionType}`)
                  )}
                </span>
              </span>
            </div>
            <div className={styles.itemContainer}>
              <span className={styles.title}>
                {t('jurorDashboard.hero.mode.title')}
              </span>
              <span className={styles.value}>
                <span className={styles.value}>
                  {isLoading || isAdmin ? (
                    <span className={'placeholder'}></span>
                  ) : (
                    t(`groupPhases.${jurorGroup?.jurorGroupPhase}`)
                  )}
                </span>
              </span>
            </div>
          </div>
        )}
      </PermissionHandler>
    </div>
  );
};

export default ControlSection;
