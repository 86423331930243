import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import * as React from 'react';
import { combine } from '../../helpers/styles';
import styles from './styles.module.scss';

const Input = ({
  className,
  icon,
  label,
  name,
  type,
  required,
  onChange,
  onClick,
  onBlur,
  value,
  readOnly,
  id,
  autoComplete,
}) => {
  const [visited, setVisited] = React.useState(false);

  return (
    <div className={styles.formGroup}>
      <input
        type={type}
        className={combine(
          styles.formControl,
          visited && styles.visited,
          className
        )}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        onBlur={() => {
          setVisited(true);
          onBlur();
        }}
        onClick={onClick}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
      <label className={styles.label} htmlFor="input">
        {label}
      </label>
      {icon && (
        <span className={styles.icon} onClick={onClick ? onClick : null}>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'time']),
  required: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  label: '',
  type: 'text',
  required: false,
  icon: null,
  onChange: () => null,
  onClick: null,
  onBlur: () => null,
  readOnly: false,
  id: 'input',
  autoComplete: null,
};

export default Input;
